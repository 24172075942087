import React from 'react';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'gatsby-plugin-intl';

import { Layout, Image } from '../components';
import messages from '../messages/about';
import afcro from '../assets/images/african-crossraods-blue.png';
import sectwo from '../assets/images/section-two-side-image.png';
import placeholder from '../assets/images/placeholder.png';

const About = () => {
  const intl = useIntl();
  return (
    <Layout>
      <Row className="about-intro-margin-top">
        <Col
          md="4"
          className="pl-5 py-5 d-flex flex-column justify-content-between presentation-section-left-wrapper"
        >
          <Image
            src={afcro}
            alt="africancrossroads"
            width="100%"
            className="afc-blue-logo"
          />
          <div className="about-intro-text my-5">
            {intl.formatMessage(messages.intro)}
          </div>
        </Col>
        <Col md="8" className="text-center py-5">
          <Image
            src={sectwo}
            alt="African Crossroads is a community of future-oriented African thinkers and doers"
            className="about-intro-image"
            width="100%"
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md="6">
          <div className="about-title">AFRICAN CROSSROADS</div>
          <div
            className="about-paragraph"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(messages.africanCrossroads)
            }}
          />
        </Col>
        <Col md="6">
          <Image src={placeholder} alt="placeholder" width="100%" />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md="4">
          <Image src={placeholder} alt="placeholder" width="100%" />
        </Col>
        <Col md="4">
          <Image src={placeholder} alt="placeholder" width="100%" />
        </Col>
        <Col md="4">
          <Image src={placeholder} alt="placeholder" width="100%" />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md="6">
          <Image src={placeholder} alt="placeholder" width="100%" />
        </Col>
        <Col md="6">
          <div className="about-title">HIVOS</div>
          <div
            className="about-paragraph"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(messages.hivos)
            }}
          />
        </Col>
      </Row>
      <div className="about-title text-center mt-5">HIVOS PROGRAMS</div>
      <Row>
        <Col md="6">
          <div
            className="about-paragraph"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(messages.hivosProgramsPartOne)
            }}
          />
        </Col>
        <Col md="6">
          <div
            className="about-paragraph"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(messages.hivosProgramsPartTwo)
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
export default About;
