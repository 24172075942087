import { defineMessages } from 'react-intl';

export const scope = 'home';

export default defineMessages({
  intro: {
    id: `${scope}.intro`,
    defaultMessage:
      'African Crossroads is a community of future-oriented African thinkers and doers'
  },
  africanCrossroads: {
    id: `${scope}.africanCrossroads`,
    defaultMessage:
      "African Crossroads is a community of future-oriented African thinkers and doers which collaborates all year long and critically reflects on the most cutting-edge entrepreneurial, scientific, artistic, and technological developments anchored in African intellectual and technological traditions.\nAfrican Crossroads is a platform shaped and used by the community to connect with fellow individuals and organisations across the continent to discuss the latest inventions and innovations in science, art, entrepreneurship and technology.\nAfrican Crossroads' mission is to create a space, physical and virtual, collective and individual, for collaboration, collective intelligence, united actions and inspiration to positively shape the future of African societies."
  },
  hivos: {
    id: `${scope}.hivos`,
    defaultMessage:
      'Hivos is an international development organization guided by humanist values. Together with local partners we aim to contribute to a free, fair and sustainable world. A world in which all citizens – women and men – have equal access to opportunities, rights and resources for development and can participate actively and equally in decision-making processes that determine their lives, their society and their future. Throughout the Middle East, Africa, Asia and Latin America.\nHivos trusts in the creativity and capacity of all people to come up with innovative solutions that improve their situation and that of society in general. Quality, cooperation and innovation are our core values and the keystone to our development and business philosophies.'
  },
  hivosProgramsPartOne: {
    id: `${scope}.hivosProgramsPartOne`,
    defaultMessage:
      'African Crossroads is an initiative of and organized by Hivos GreenWorks (formely BOOST program) and R.O.O.M. programs, funded by the Dutch foreign ministry, and the Swedish International Development Cooperation Agency (SIDA), Rijksdienst voor Ondernemend Nederland (RVO), and the British Council.'
  },
  hivosProgramsPartTwo: {
    id: `${scope}.hivosProgramsPartTwo`,
    defaultMessage:
      'Hivos is a humanist development organisation based in The Hague, The Netherlands, which supports frontrunners to innovate for social change.'
  }
});
